<script>
import NavHeader from "@/components/top/NavHeader.vue";
import FenXiangTiShi from "@/components/btn/fenXiangTiShi.vue";
import {useRoute} from "vue-router";
import {each, get, map, toInteger} from "xe-utils";
import qiYeWeiXi from "@/api/QiYeWeiXi.js";
import {computed, onMounted, ref} from "vue";
import http from "@/api/http.js";
import {getKeHuTel, getZaiXianKeFu} from "@/api/dataList/publicApi";
import YzCollapse from "@/package/YzCollapse/index.vue";

export default {
  components: {YzCollapse, FenXiangTiShi, NavHeader},
  setup() {
    const route = useRoute()
    const navMenuId = toInteger(get(route, 'params.navMenuId'))
    const info = ref({})

    onMounted(() => {
      http.get('/gzhphp/policy/get_approval_process_info', {params: {navMenuId}}).then(da => {
        if (da.code === 1) {
          da.data.join_shen_pi_guo_cheng_info = map(da.data.join_shen_pi_guo_cheng_info, n => {
            n.zheDieZhuangTai = n.is_zhe_die === '是' ? false : true  //没开启折叠，默认展开，开启折叠，默认关闭
            return n
          })
          info.value = da.data

          qiYeWeiXi.fenXiang({
            title: info.value.name,
            desc: '赶快点击进来查看详情吧',
            link: location.href
          })
        }
      })


    })

    function zhanKai(offset) {
      each(info.value.join_shen_pi_guo_cheng_info, (n, i) => {
        if (i === offset) return //点击的那个跳过
        if (n.is_zhe_die === '是') {
          info.value.join_shen_pi_guo_cheng_info[i].zheDieZhuangTai = false
        }
      })
      info.value.join_shen_pi_guo_cheng_info[offset].zheDieZhuangTai = !info.value.join_shen_pi_guo_cheng_info[offset].zheDieZhuangTai
    }

    function opKeFu() {
      getZaiXianKeFu().then(({code, data}) => {
        if (code === 1) {
          location.href = data.content
        }
      })
    }

    const join_shen_pi_guo_cheng_info = computed(() => {
      return map(info.value.join_shen_pi_guo_cheng_info, (n, i) => {
        return {
          id: i + 1,
          title: n.name,
          html: n.content,
          hiddenZheDie: n.is_zhe_die === '是',
        }
      })
    })

    const tel = ref('')
    //获取电话号码
    getKeHuTel().then(({code, data}) => {
      tel.value = get(data, 'content')
    })

    return {
      tel,
      join_shen_pi_guo_cheng_info,
      opKeFu,
      zhanKai,
      get,
      info,
      route,
    }
  }
}
</script>

<template>
  <div class="tal-w-full tal-h-full tal-flex tal-flex-col tal-bg-white">
    <nav-header fixed :title="get(info, 'name', '')">
      <template #right>
        <fen-xiang-ti-shi/>
      </template>
    </nav-header>
    <div class="tal-flex-1 tal-overflow-auto">
      <div class="tal-p-2 tal-pl-3 tal-pr-3">
        <yz-collapse top-background="#f5f5f5" :data-list="join_shen_pi_guo_cheng_info"/>
      </div>
    </div>
    <div class="tal-overflow-hidden tal-pt-2">
      <div class="yin-ying-2 tal-p-2">
        <a  :href="`tel:${tel}`" class="tal-btn dian-hua tal-bg-blue-400 tal-text-white">电话咨询</a>
        <a href="javascript:" class="tal-btn zai-xian-ke-fu tal-bg-zhu-se tal-text-white" @click="opKeFu">在线咨询</a>
      </div>
    </div>

  </div>
</template>

<style scoped lang="less">
@import "~@/assets/basis_css/style/var";

.yin-ying-2 {
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-around;


  .tal-btn {
    flex: 1;
    text-align: center;
    font-size: 1.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &.dian-hua {
      border-radius: 50px 0 0 50px;
    }
    &.zai-xian-ke-fu {
      border-radius: 0 50px 50px 0;
    }
  }
}


</style>
