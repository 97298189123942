import http from "@/api/http";
import {once} from "xe-utils";

let publicApi = {
    /**
     * 获取客服电话
     * @returns {Promise<unknown>}
     */
    getKeHuTel() {
        return new Promise((s, e) => {
            getKeHuTel().then(da => {
                s(da.data.content)
            }).catch(e => {
                e(e)
            })
        })
    },
    /**
     * 擅长领域
     * @returns {Promise<unknown>}
     */
    getShangChangJiNeng() {
        return new Promise((s, e) => {
            http.get("/portal/index/get_shang_chang_ji_neng").then(da => {
                s(da.data)
            }).catch(e => {
                e(e)
            })
        })
    }
}

//获取惠企政策底部菜单
export const getPolicyFooterData = once(() => {
    return http.get("/portal/index/getNav", {params: {nav_id: 32}});
})
//获取在线客服
export const getZaiXianKeFu = once(() => {
    return http.get("/portal/index/getKeFuUrl");
})

//获取客服电话
export const getKeHuTel = once(() => {
    return http.get("/portal/index/get_ke_hu_tel")
})

export default publicApi
