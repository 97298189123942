<script>
import {computed, ref} from 'vue'
import {indexOf, isUndefined, map, remove} from "xe-utils";

export default {
  name: 'YzCollapse',
  props: {
    topBackground: String, //头部背景颜色
    dataList: {
      type: Array,
      required: true,

      /*
      * [
          {
          * id:''//唯一id
            title: '测试',
            html: '',
            status: false,//默认是否折叠 false=折叠 true=展开
            hiddenZheDie: false //是否隐藏折叠 ，如果是false，默认折叠自动强制展开
          }
        ]
      *
      * */
    },
  },
  setup(props) {
    const zhangKaiId = ref([])
    const getList = computed(() => {

      return map(props.dataList, n => {
        n.status = indexOf(zhangKaiId.value, n.id) !== -1 //是否展开
        if (isUndefined(n.hiddenZheDie)) n.hiddenZheDie = true
        if (n.hiddenZheDie === false) { //如果隐藏折叠 默认折叠自动强制展开
          n.status = true
        }
        return n
      })
    })

    function queHuan({id, hiddenZheDie}) {
      if (!hiddenZheDie) return
      if (indexOf(zhangKaiId.value, id) !== -1) {
        //删除
        remove(zhangKaiId.value, n => n === id)
      } else {
        //添加
        zhangKaiId.value.push(id)
      }
    }

    return {
      queHuan,
      getList
    }
  }
}
</script>

<template>
  <div>
    <div v-for="vo in getList" :key="vo.id">
      <div class="tou-bu" :style="{'background-color': topBackground}" @click="queHuan(vo)">
        <div class="zhe-die-title">
          <div class="title">
            {{ vo.title }}
          </div>
          <div v-if="vo.hiddenZheDie">
            <van-icon name="arrow-up" color="#aaa" v-if="vo.status"/>
            <van-icon name="arrow-down" color="#aaa" v-else/>
          </div>
        </div>
        <div class="xian-tiao" v-if="vo.status"></div>
      </div>
      <div
          class="nei-rong-html"
          v-html="vo.html"
          v-if="vo.status"
      >
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
@padding: 0 5px;
.tou-bu {
  padding: @padding;

  .zhe-die-title {
    display: flex;
    font-size: 15px;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 0;
    align-items: center;
    font-weight: bolder;

    .title {
      border-left: 3px solid #4A4096;
      padding-left: 5px;
    }
  }

  .xian-tiao {
    border-bottom: 1px solid #f5f5f5;
  }
}

.nei-rong-html {
  padding: 10px 5px;
  border-bottom: 1px solid #f5f5f5;
}

</style>
